@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  padding: 0;
  margin: 0;
}

a {
  color: inherit;
  text-decoration: underline;
}

li[role="menuitem"] a {
  text-decoration: none;
}

* {
  box-sizing: border-box;
  --font-monospace: JetBrains Mono, Fira Code, Victor Mono, monospace;
}

.ant-menu-item {
  height: 32px !important;
  line-height: 32px !important;
}

.ant-menu-item-group-title {
  padding-bottom: 0 !important;
  padding-top: 16px !important;
}

.ant-tour.wide {
  width: min(800px, 100vw);
  max-width: fit-content;
}

:root {
  --color-gray-50: #fafafa;
  --color-gray-100: #f4f4f5;
  --color-gray-200: #e4e4e7;
  --color-gray-300: #d4d4d8;
  --color-gray-400: #a1a1aa;
  --color-gray-500: #71717a;
  --color-gray-600: #52525b;
  --color-gray-700: #3f3f46;
  --color-gray-800: #27272a;
  --color-gray-900: #18181b;
  --color-gray-950: #09090b;
}
