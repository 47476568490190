.cardContainer {
  margin-block: 1em 2em;
  text-align: center;
}

.listContainer {
  width: 100%;

  :global(.ant-row) {
    gap: 0.25rem 0 !important;
  }
}

.hazard,
.hazardSignificant {
  margin-bottom: 0rem !important;
  text-align: start;
  gap: 0.25rem;
  display: flex !important;
  justify-content: flex-start !important;
  padding: 0 !important;
  color: var(--color-gray-400) !important;
}

.hazardSignificant {
  font-weight: 600;
  color: var(--color-gray-800) !important;
}

.container {
  container-type: inline-size;
  width: 100%;
}

.wrapper {
  display: grid;
  grid-template-columns: 1fr;
  .label {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 2px solid var(--color-gray-100);
  }
}

@container (min-width: 500px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr;

    .listContainer {
      grid-column: 2/4;
    }
    .label {
      border: none;
    }
  }
}

.label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  button {
    margin-top: -2px;
  }
}
