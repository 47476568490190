/** Container for the title text ("Powered by"). */
.title {
  color: var(--color-gray-500) !important;
  text-align: center;
  font-weight: 400 !important;
  font-size: 0.85em !important;
  margin: 0 !important;
  padding-block-end: 2px;
}

/** XDI logo. */
.logo {
  object-fit: contain;
}
