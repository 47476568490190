.container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: rgba(255, 255, 255, 0.5);
  z-index: 1000;

  &--opaque {
    background-color: white;
  }
}

.icon {
  font-size: 3rem;
}
