.smallText {
  & > span {
    display: flex;
    align-items: center;
  }

  // tiny circles
  & > span:not(:first-child)::before {
    content: "";
    height: 0.125rem;
    width: 0.125rem;
    background: var(--color-gray-500);
    display: inline-block;
    margin-inline: 0.5rem;
  }
}

