.navButtons {
  display: flex;
  justify-content: space-between;
  padding-bottom: 16px;
}

.content {
  border: 1px solid var(--color-gray-100);
  border-radius: 8px;
  padding: 16px;
  padding-bottom: 32px;
}

.ratings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 192px;
}

.ratingsInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 16px;
  margin-bottom: -8px;
  border-bottom: 1px solid var(--color-gray-100);
  color: var(--color-gray-400);
  row-gap: 4px;
  button {
    color: inherit;
    margin-top: -2px;
  }
}

.label {
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 4px;
  button {
    margin-top: -2px;
  }
}

.fadeIn {
  animation: fadeIn 0.6s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}
