$stroke-width: 6px;
$stroke-width-medium: 4px;
$stroke-width-small: 3px;
$stroke-width-tiny: 2px;

.outer {
  border-radius: 50%;
  display: inline-block;
  position: relative;
  user-select: none;
  cursor: default;

  &Wordy {
    border-radius: 4px;
  }
}

.inner {
  border-radius: 50%;
  position: absolute;

  left: $stroke-width;
  right: $stroke-width;
  top: $stroke-width;
  bottom: $stroke-width;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &Wordy {
    border-radius: 2px;
    text-align: center;
  }

  &--single-large {
    font-size: 3.5rem;
  }

  &--single-medium {
    font-size: 2.25rem;
    left: $stroke-width-medium;
    right: $stroke-width-medium;
    top: $stroke-width-medium;
    bottom: $stroke-width-medium;
  }

  &--single-small {
    font-size: 1.5rem;
    left: $stroke-width-small;
    right: $stroke-width-small;
    top: $stroke-width-small;
    bottom: $stroke-width-small;
  }

  &--single-tiny {
    font-size: 0.75rem;
    left: $stroke-width-tiny;
    right: $stroke-width-tiny;
    top: $stroke-width-tiny;
    bottom: $stroke-width-tiny;
  }

  &--wordy-large {
    left: $stroke-width-medium * 0.75;
    right: $stroke-width-medium * 0.75;
    top: $stroke-width-medium * 0.75;
    bottom: $stroke-width-medium * 0.75;
  }

  &--wordy-medium {
    left: $stroke-width-small * 0.8;
    right: $stroke-width-small * 0.8;
    top: $stroke-width-small * 0.8;
    bottom: $stroke-width-small * 0.8;
  }

  &--wordy-small {
    left: $stroke-width-tiny * 0.8;
    right: $stroke-width-tiny * 0.8;
    top: $stroke-width-tiny * 0.8;
    bottom: $stroke-width-tiny * 0.8;
  }

  &--wordy-tiny {
    left: $stroke-width-tiny * 0.5;
    right: $stroke-width-tiny * 0.5;
    top: $stroke-width-tiny * 0.5;
    bottom: $stroke-width-tiny * 0.5;
  }

  &--dark {
    background-color: black;
    color: white;
  }

  &--light {
    background-color: #ffffff;
    color: black;
  }
}

.label {
  font-size: 1.4rem;
  line-height: 1;
}
