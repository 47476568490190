.titleContainer {
  display: grid;
  gap: 0.5em;
  padding-block-end: 1em;
}

.title {
  padding: 0;
  margin: 0;
  font-size: 1.4em !important;
}

.blurb {
  padding: 0;
}

.sliderContainer {
  padding: 1em 1em 1.5em 1em;

  :global(.ant-slider-mark-text) {
    padding-block-start: 0.25em;
  }
}
