$screen-md: 1000px;
$screen-lg: 1300px;
$screen-xl: 1500px;

.container {
  --header-height: 520px;
  padding: 0px 16px;

  @media screen and (min-width: $screen-md) {
    --header-height: 400px;
  }

  @media screen and (min-width: $screen-xl) {
    --header-height: 360px;
    padding: 0;
  }
}

.header {
  height: var(--header-height);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-bottom: 16px;
  border-radius: 0 0 8px 8px;
  overflow: hidden;
}

.imageWrapper {
  height: var(--header-height);
  width: 100%;
  position: absolute;

  img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -2;
  }

  .overlay {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    z-index: -1;
    mix-blend-mode: multiply;
  }
}

.title {
  font-size: 1.7rem;
  font-weight: 600;
  padding: 0 32px 16px 32px;
  color: #f7eefa;
  width: 100%;
  display: block;
  margin: 0;

  @media screen and (min-width: $screen-md) {
    font-size: 1.9rem;
  }

  @media screen and (min-width: $screen-lg) {
    font-size: 2.1rem;
  }
}

.primaryButton {
  width: max-content;
  margin-left: 32px;
}

.subtitle {
  font-size: 1.05rem;
  color: #4b1553cc;
  color: #f7eefa;
  padding: 0 32px;
  opacity: 0.85;
}

.card {
  text-align: center;
  height: 100%;

  :global(.ant-card-body) {
    display: flex;
    flex-direction: column;
    height: 100%;
    padding: 0 !important;
  }

  &Icon {
    aspect-ratio: 2/1;
    width: 100%;

    @media screen and (min-width: $screen-lg) {
      aspect-ratio: 3/2;
    }

    svg {
      height: 100%;
      width: 100%;
    }
  }

  &Body {
    flex: 1;
  }

  &Title {
    font-size: 1.1rem !important;
    margin: 0;
    padding-bottom: 0.5rem;
    align-content: center;

    height: 3em;

    @media screen and (min-width: $screen-lg) {
      height: 5em;
    }
  }

  &Button {
    white-space: normal;
    height: auto;
    width: 100%;
    &:hover {
      cursor: pointer !important;
    }
  }

  &ContentWrapper {
    padding: 12px;
    padding-bottom: 16px;
  }
}

.disclaimer {
  margin-bottom: 2rem;
  color: var(--color-gray-400);
  line-height: 1.5;
  margin-top: 64px;
}

.tools {
  display: grid;
  grid-template-columns: minmax(240px, 1fr);
  gap: 8px;

  @media screen and (min-width: $screen-md) {
    grid-template-columns: repeat(2, minmax(240px, 1fr));
  }
  @media screen and (min-width: $screen-lg) {
    grid-template-columns: repeat(4, minmax(240px, 1fr));
  }
}
