.button {
  display: flex;
  height: 14px;
  padding: 0;
  margin: 0;
  margin-top: -2px;
  font-size: 14px;
  line-height: 14px;

  :global(.ant-wave) {
    display: none !important;
  }

  &:hover {
    opacity: 0.75;
  }
}
