.map {
  min-height: 32rem;
  border-radius: 6px;
  overflow: hidden;
}

.fadeIn {
  animation: fadeIn 0.6s;
  animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
}

@keyframes fadeIn {
  0% {
    opacity: 0.25;
  }
  100% {
    opacity: 1;
  }
}

.navStepsContainer {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  align-items: center;
  justify-content: space-between;
}

.navSteps {
  max-width: 400px;
  justify-self: center;
  grid-column: 2;
  :global(.ant-steps-item-content) {
    margin-top: 0 !important;
  }
}

.stepsContainer {
  margin-block: 16px 32px;
  border: 1px solid var(--color-gray-100);
  width: 100%;
  border-radius: 8px;
  padding: 16px;
}
