.easeUp {
  animation: ease-up 2s ease-in-out infinite none running;
}

@keyframes ease-up {
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  15% {
    opacity: 1;
    transform: translateY(0%);
  }
  85% {
    opacity: 1;
    transform: translateY(0%);
  }
  100% {
    opacity: 0;
    transform: translateY(-100%);
  }
}
