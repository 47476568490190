.appName {
  width: 12em;
  margin-left: -16px;
  padding: 0 16px;
  height: 48px !important;
  font-weight: 500;
  width: 256px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  &:hover {
    background: #f3f3f3;
  }
}

.logoContainer {
  height: 32px;
  width: auto;
  :global(.ant-image),
  :global(.ant-image-img) {
    height: 100%;
    width: auto;
  }
}

.currentOrgName {
  font-size: 1.1rem;
  font-weight: 600;
  // width: 160px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.link {
  &:hover {
    cursor: pointer;
  }
}
