.layout {
  --border-color: #e5e7eb;
}

@mixin align-centre() {
  margin: 0 auto;
  // max-width: 1200px;
  padding: 0 16px;
  width: 100%;
}

.layout {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  overflow: hidden;
}

.branding {
  background-position: center center;
  background-size: cover;
  height: 50px;
  min-height: 50px;
  width: 100%;
  border-bottom: 1px solid var(--border-color);

  &Content {
    @include align-centre();
    height: 100%;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &Title {
    position: absolute;
    left: 2rem;
    bottom: 1rem;
  }
}

.content {
  margin: 0 auto;

  &Container {
    overflow-y: auto;
    width: 100%;
  }
}

.language {
  position: absolute;
  right: 16px;
  top: 16px;
}

.headerContent {
  @include align-centre();
  padding: 0;

  .sidebar {
    background: white !important;
    overflow-y: auto;
    padding-inline: 0 !important;
    border-right: 1px solid var(--border-color);
    padding-top: 8px;

    :global(.ant-layout-sider-children) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    &Menu {
      border: 0 !important;

      :global(.ant-menu-item) {
        border-radius: 4px;

        display: flex;
        flex-direction: row;
        align-items: flex-start;

        // Wrap items
        word-wrap: normal;
        white-space: normal;
        height: auto !important;
        color: var(--color-gray-900);

        svg {
          margin-top: 9px;
        }
      }

      :global(.ant-menu-item-selected) {
        font-weight: 700;
        opacity: 0.95;
      }
    }
  }
}

.backLink {
  width: 100%;
  justify-content: flex-start;
  border-radius: 0;
  margin-top: -8px;
  height: 40px;
  &Container {
    border-bottom: 1px solid #e5e7eb;
  }
}

.title {
  cursor: pointer;
  margin: 0 !important;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 1rem !important;

  code {
    font-weight: normal;
  }
}

.attributionContainer {
  background: var(--color-gray-50);
  padding: 8px 0;
  border-bottom: 1px solid var(--color-gray-100);
  margin-block-start: 8px;

  img {
    filter: grayscale(100%) opacity(33%);
    transition: all 0.3s ease-out;
  }

  img:hover {
    filter: none;
  }
}
