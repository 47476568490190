.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 8px;
  margin-block-end: 1em;
}

.title {
  font-weight: bold;
  font-size: 1.8rem;
  margin: 0;
  line-height: 1;
}

.titleIcon {
  font-size: 3.5rem;
  margin-top: -0.05em;
}

.blurbContainer {
  padding-block: 16px 4px;
  border-top: 2px solid #f1f5f9;
}

.blurb {
  margin-block-start: 0;
  font-size: 1rem;
}
